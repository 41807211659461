import React from 'react';
import { MultiClickTable } from '../../components/MultiClickTable';
import { MultiCheckTable } from '../../components/MultiCheckTable';
import ScheduleCard from '../../components/ScheduleCard';
import StaticSlotList from '../../components/StaticSlotList';
import { TouchTable } from '../../components/TouchTable';
import SchedulerPage from '../SchedulerPage';
import findSolution from '../../solvers/FSolver';
import { ClickTable } from '../../components/ClickTable';
import { CheckTable } from '../../components/CheckTable';
import { getColor } from '../../utils/ColorMapper';
import { generateFulfillmentSlotContent } from '../../utils/SlotContentFactory';
import { MultiDisplayTable } from '../../components/MultiDisplayTable';
import { FULFILLMENT_SCHEDULER_NAME, INVALID_SLOT_DELETE_MESSAGE, MAX_SLOT_ADD_MESSAGE, MAX_SLOT_WARNING } from '../../utils/Constants';
import { capitalize } from '../../utils/StringUtils';
import { withErrorBoundary } from '../../components/ErrorBoundary';

class FulFillmentSchedulerPage extends SchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <ScheduleCard icon="/fulfillment_logo.svg"/>,
            slotList: () => <StaticSlotList contentFactory={generateFulfillmentSlotContent(this.state.time)} />,
            displayTable: () => <MultiDisplayTable/>
        };
    }

    openDialog (action, passthroughVars) {
        return () => {
            switch(action) {
                case "add":
                    if (this.slotsRef.current.getSize() >= 6) {
                        this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max 6)`});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                case "delete":
                    if (this.state.scheduleActive || this.state.displayActive || this.slotsRef.current.getSize() === 0) {
                        this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                default:
                    super.openDialog(action, passthroughVars)();
            }
        }
    }
    
    findSolution(timesMap, intervalsMaps, metadata) {
        let messages = [];
        if (intervalsMaps.length > 6) {
            intervalsMaps = intervalsMaps.slice(0, 6);
            messages.push({severity: "warning", content: MAX_SLOT_WARNING});
        }
        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};

        let slotsInfo = metadata["slotsInfo"];
        if (intervalsMaps.length === 0) {
            return [{messages: [{severity: "error", content: "There are no slots to schedule."}]}];
        }
        let {density, schedules, missing} = findSolution(timesMap, intervalsMaps, slotsInfo["prefTotalArray"]);
        if (schedules === undefined) {
            return undefined;
        }
        let solutionDatas = [];
        let densityData = {};
        for (let rowIndex in density) {
            if (densityData[rowIndex] === undefined) { 
                densityData[rowIndex] = [];
            }
            let array = densityData[rowIndex];
            for (let {interval, magnitude, capacity} of density[rowIndex]) {
                array.push({interval: interval, borderColor: getColor("green", magnitude / capacity * 800 + 300), color: getColor("green", magnitude / capacity * 700 + 100), label: [`${magnitude} of ${capacity} slots filled`]})
            }
        }
        solutionDatas.push({title: "Overview", solutionData: densityData})
        for (let i of Object.keys(schedules)) {
            let solutionData = {};
            for (let rowIndex in schedules[i]) {
                if (solutionData[rowIndex] === undefined) { 
                    solutionData[rowIndex] = [];
                }
                let array = solutionData[rowIndex];
                for (let interval of schedules[i][rowIndex]) {
                    array.push({interval: interval, borderColor: getColor(slotsInfo["colorArray"][i], 800), color: getColor(slotsInfo["colorArray"][i], 500), label: [slotsInfo["nameArray"][i]]})
                }
            }
            solutionDatas.push({title: `${slotsInfo.nameArray[i]}`, solutionData: solutionData})
        }
        if (missing.length > 0) {
            messages.push({severity: "warning", content: "Could not schedule: " + missing.map((index) => slotsInfo["nameArray"][index]).join(", ")});
        }

        let tables = [];
        for (let i = 0; i < solutionDatas.length; i += 1) {
            tables.push({messages: messages[i], table: solutionDatas[i]});
        }
        return tables;
    }

    getTableType() {
        if (window.navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/i)) {
            return this.state.complexTableType ? TouchTable : MultiCheckTable;
        } else {
            if (this.state.scheduleActive) {
                return this.state.complexTableType ? MultiClickTable : MultiCheckTable;
            }
            return this.state.complexTableType ? ClickTable : CheckTable;
        }
    }

    componentDidMount() {
        document.title = `${capitalize(FULFILLMENT_SCHEDULER_NAME)} scheduler`;
    }
}

export default withErrorBoundary(FulFillmentSchedulerPage);