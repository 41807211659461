import ScheduleCard from "./ScheduleCard";
import {doc, collection, onSnapshot, addDoc, updateDoc, query, orderBy} from "firebase/firestore";
import {defaultValues} from '../utils/DataUtils';
import {findAppendedOrder} from "../utils/OrderEncoderUtils";
import {OPERATION_FAILED, WRITE_FAILED_DETAIL} from "../utils/Constants";
import { defaultEndDate, defaultEndTime, defaultStartTime } from "../initial_data";
import lodash from "lodash";
import {initializeSolutionDocumentData} from "../utils/DocumentUtils";

export default class DynamicScheduleCard extends ScheduleCard {
	constructor(props) {
		super(props);
		this.state.selected = true;
		this.solution = [];
		onSnapshot(query(collection(this.props.rootPath, "solutions"), orderBy("order")), (snapshot) => {
			let solution = [];
			snapshot.forEach((doc) => {
				let data = doc.data();
				initializeSolutionDocumentData(data);
				solution.push(data);
				data.id = doc.id;
			});
			this.solution = solution;
		});
		onSnapshot(this.props.rootPath, (document) => {
            let data = document.data();
            //console.log(`${document.id} => ${JSON.stringify(data)}`);
			let defaultData = {name: "Untitled", solution: {colors: "[]", data: "[]", names: "[]", uid: 0}, sharing: {enabled: false, key: "0"}, complexTableType: true}
			defaultValues(data, defaultData);
			if (data.scheduleData && data.scheduleData.data) {
				data.scheduleData.data = JSON.parse(data.scheduleData.data)
			}
			if (this.props.selected && !lodash.isEqual(this.scheduleData, data.scheduleData)) {
				this.props.dataChangeAction([data.scheduleData.data, data.scheduleData.scheduleStart, data.scheduleData.scheduleEnd, data.scheduleData.timeFormat, data.scheduleData.selectAll]);
			}
			this.scheduleData = data.scheduleData;
            this.setState({title: data.name, sharing: data.sharing}, () => {
				if (this.props.selected) {
					this.props.tableChangeAction({"complexTableType": data.complexTableType})
				}
				onSnapshot(doc(this.props.rootPath, this.state.sharing.key, "DEFAULT"), (doc) => {
					let data = doc.data();
					let defaultData = {endDate: defaultEndDate, endDay: 6, endTime: defaultEndTime, interval: 30, span: 1, startDate: defaultStartTime, startDay: 0, startTime: defaultStartTime, timeFormat: true}
					defaultValues(data, defaultData);
					this.props.tableChangeAction({"time": data.timeFormat, "interval": data.interval, "span": data.span, "startTime": data.startTime,
						"endTime": data.endTime, "startDate": data.startDate, "endDate": data.endDate, "startDay": data.startDay, "endDay": data.endDay});
					this.props.onLoadFinish();
				}, () => {
					this.props.propChangeAction({"notFound": true});
				});
			})
        }, () => {
			this.props.propChangeAction({"notFound": true});
		});
	}

	saveState(field, value) {
		let publicAccessSet = new Set(["time", "interval", "span", "startTime", "endTime", "startDate", "endDate", "startDay", "endDay"]);
		let fieldMapping = {time: "timeFormat", interval: "interval", span: "span", startTime: "startTime",endTime: "endTime",
							startDate: "startDate", endDate: "endDate", startDay: "startDay", endDay: "endDay", title: "name", 
							sharingEnabled: "sharing.enabled", complexTableType: "complexTableType"};
		if (publicAccessSet.has(field)) {
			updateDoc(doc(this.props.rootPath, this.state.sharing.key, "DEFAULT"), {
				[fieldMapping[field]]: value
			}).catch(() => {
				this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
			});
		} else {
			updateDoc(this.props.rootPath, {
				[fieldMapping[field]]: value
			}).catch(() => {
				this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
			});
		}
		super.saveState(field, value);
	}

	saveDataToActive(data, timeFormat, scheduleStart, scheduleEnd, selectAll) {
		updateDoc(this.props.rootPath, {
			"name": this.state.title,
			"scheduleData.data": JSON.stringify(data), 
			"scheduleData.scheduleStart": scheduleStart, 
			"scheduleData.scheduleEnd": scheduleEnd,
			"scheduleData.timeFormat": timeFormat,
			"scheduleData.selectAll": selectAll
		}).catch(() => {
			this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
		});
		super.saveDataToActive(data, timeFormat, scheduleStart, scheduleEnd, selectAll);
	}

	async saveSolution(solution) {
		if (solution) {
			solution.order = findAppendedOrder(this.solution.map((solution) => solution.order));
			solution.title = `solution_${Date.now()}`
			solution.times = JSON.stringify(solution.times);
			solution.intervalsMaps = JSON.stringify(solution.intervalsMaps);
			try {
                const docRef = await addDoc(collection(this.props.rootPath, "solutions"), solution);
                //this.solution.push(solution);
                return docRef.id;
            } catch (error) {
                this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
                return null;
            }
		}
		//super.saveSolution(solution);
	}

	getSharingDetails() {
		return this.state.sharing;
	}

	render() {
		if (this.props.hide) {
			return null;
		} else {
			return super.render();
		}
	}
}