import React from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import { Draggable } from "react-beautiful-dnd"
import { getColor } from '../utils/ColorMapper'
import { print } from "../utils/DataUtils";

export default class Slot extends React.Component {
  constructor(props) {
      super(props);
      //init name/color for content that does not dontain name/color
      let state = {expanded: false, name: this.props.name, color: this.props.color};
      this.props.attributeContentList.forEach((contentDict) => {
        state[contentDict.key] = contentDict.value;
      });
      this.state = state;
  }

  handleChange(field, value) {
    switch (field) {
      case "expand":
        this.setState({expanded: !this.state.expanded});
        this.props.propChangeAction("expand", !this.state.expanded);
        break;
      case "color":
        this.setState({[field]: value}, () => {
          if (this.props.selected) {
            this.props.propChangeAction(field, value);
          }
        });
        break;
      case "length":
        this.setState({"length": parseInt(value)});
        break;
      default:
        this.setState({[field]: value})
    }
  }

  handleEventChange(field) {
    return (event) => {
      let value = event.target.value;
      this.handleChange(field, value);
    }
  }

  setExpanded(expanded) {
    this.setState({expanded: expanded})
  }

  setColor(color) {
    this.handleChange("color", color);
  }

  slotOnClick = (event) => {
    event.stopPropagation();
    this.props.slotClickAction({color: this.state.color, data: this.getScheduleData()});
  }

  render() {
    let subtitle = this.props.subtitle;
    if (subtitle !== undefined) {
      this.props.attributeContentList.forEach((contentDict) => {
        subtitle = subtitle.replace(`{${contentDict.key}}`, this.state[contentDict.key]);
      });
    }

    return (
      <Draggable draggableId={this.props.id} index={this.props.index}>
        {(provided) => (
          <div  {...provided.draggableProps}>
            <Card style={{width: "100%", backgroundColor: this.props.selected ? getColor(this.state.color, 50) : null}} ref={provided.innerRef} sx={{ border: 1, borderColor: getColor(this.state.color, 500) }} onClick={this.slotOnClick} >
              <CardHeader
                avatar={
                  <Box {...provided.dragHandleProps} sx={{ width: 10, height: 40, bgcolor: getColor(this.state.color, 500) }} onMouseDown={() => this.setExpanded(false)}/>
                }
                action={
                  <IconButton aria-label="settings" onClick={this.handleEventChange("expand")}>
                    <SettingsIcon />
                  </IconButton>
                }
                sx={{
                  overflow: "hidden",
                  "& .MuiCardHeader-content": {
                    overflow: "hidden"
                  }
                }}
                title={this.state.name}
                titleTypographyProps={{ noWrap: true }}
                subheader={subtitle}
                subheaderTypographyProps={{ noWrap: true }}
              />
              {(this.props.attributeContentList.length || this.props.auxContentList.length) && <Collapse in={this.state.expanded} timeout="auto">
                <CardContent>
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    <>
                      {this.props.attributeContentList.map((contentMap, index) => (
                        <React.Fragment key={index}>
                          {React.cloneElement(contentMap.component, {label: contentMap.key, value: this.state[contentMap.key], onChange: this.handleEventChange(contentMap.key)}) }
                        </React.Fragment>
                      ))}
                    </>
                  </Box>
                  {this.props.auxContentList.map((content, index) => (
                        <React.Fragment key={index}>
                          {content}
                        </React.Fragment>
                  ))}
                </CardContent>
              </Collapse>}
            </Card>
          </div>
        )}
      </Draggable>
    );
  }

  getSlotInfo() {
    let slotInfo = {};
    this.props.attributeContentList.forEach((contentDict) => {
      slotInfo[contentDict.key] = this.state[contentDict.key];
    });
    if (slotInfo.name === undefined && this.state.name !== undefined) {
      slotInfo.name = this.state.name;
    }
    if (slotInfo.color === undefined && this.state.color !== undefined) {
      slotInfo.color = this.state.color;
    }
    return slotInfo;
  }

  getScheduleData() {
    if (this.scheduleData) {
      return [this.scheduleData.data, this.scheduleData.scheduleStart, this.scheduleData.scheduleEnd, this.scheduleData.timeFormat, this.scheduleData.selectAll];
    }
    return [undefined, undefined, undefined, true, undefined];
  }

  saveData(data, timeFormat, scheduleStart, scheduleEnd, selectAll) {
    if (data !== undefined) { //there are use cases where we only want to update attributes that data will intentionally be undefined for dynamic
      this.scheduleData = {
        timeFormat: timeFormat,
        data: data,
        scheduleStart: scheduleStart,
        scheduleEnd: scheduleEnd,
        selectAll: selectAll
      };
    }
  }

  toString(startDate, timeFormat) {
    let [data, scheduleStart, scheduleEnd, scheduleTimeFormat, selectAll] = this.getScheduleData();
    let title = `${this.state.name} (${this.state.length} ${timeFormat ? "minutes" : "days"})`;
    return print(title, data, scheduleStart, scheduleEnd, selectAll, scheduleTimeFormat, startDate, timeFormat);
  }
}