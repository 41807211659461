import React from 'react';
import ScheduleCardList from '../../components/ScheduleCardList';
import StaticSlotList from '../../components/StaticSlotList';
import SchedulerPage from '../SchedulerPage';
import findSolution from '../../solvers/DSolver';
import { getColor } from '../../utils/ColorMapper';
import { generateNcSlotContent } from '../../utils/SlotContentFactory';
import { MultiDisplayTable } from '../../components/MultiDisplayTable';
import { process2dData, scaleData } from '../../utils/DataUtils';
import {
    DISTRIBUTION_SCHEDULER_NAME,
    INVALID_SLOT_DELETE_MESSAGE,
    MAX_SLOT_ADD_MESSAGE,
    MAX_SLOT_WARNING,
    MIN_SLOT_DELETE_MESSAGE, MORE_EVENTS_UNASSIGNED,
    MORE_SLOTS_UNASSIGNED
} from '../../utils/Constants';
import { capitalize } from '../../utils/StringUtils';
import { withErrorBoundary } from '../../components/ErrorBoundary';

class DistributionSchedulerPage extends SchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <ScheduleCardList icon="/distribution_icon_free.png"/>,
            slotList: () => <StaticSlotList contentFactory={generateNcSlotContent(this.state.time)} />,
            displayTable: () => <MultiDisplayTable/>
        };
    }

    dialogAction (action) {
        switch(action) {
            case "add":
                return (properties) => {
                    if (properties.slotsAdd) {
                        this.slotsRef.current.addSlot(properties.attributes);
                    } else {
                        this.scheduleRef.current.addSlot(properties.attributes);
                    }
                }
            default:
                return super.dialogAction(action);
        }
    }

    openDialog (action, passthroughVars) {
        return () => {
            switch(action) {
                case "add":
                    passthroughVars = passthroughVars || {};
                    if (passthroughVars["slotsAdd"] === undefined) {
                        passthroughVars["slotsAdd"] = !this.state.scheduleActive;
                    }

                    if (!passthroughVars["slotsAdd"] && this.scheduleRef.current.getSize() >= 6 ||
                        passthroughVars["slotsAdd"] && this.slotsRef.current.getSize() >= 6) {
                        this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max 6)`});
                    } else {
                        let [content, attributes] = passthroughVars["slotsAdd"] ? this.slotsRef.current.getDefaultAttributesContent() : this.scheduleRef.current.getDefaultAttributesContent();
                        this.addDialogRef.current.handleClickOpen({content: content, defaultAttributes: attributes}, passthroughVars);
                    }
                    break;
                case "delete":
                    if ((this.state.scheduleActive && this.scheduleRef.current.getSize() <= 1)) {
                        this.setState({snackbarOpen: true, snackbarMessage: MIN_SLOT_DELETE_MESSAGE});
                    } else if (this.state.displayActive || (!this.state.scheduleActive && this.slotsRef.current.getSize() === 0)) {
                        this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
                    } else {
                        super.openDialog(action, {}, passthroughVars)();
                    }
                    break;
                default:
                    super.openDialog(action, {}, passthroughVars)();
            }
        }
    }

    findSolution(timesMaps, intervalsMaps, metadata) {
        let messages = [];
        if (intervalsMaps.length > 6) {
            intervalsMaps = intervalsMaps.slice(0, 6);
            messages.push({severity: "warning", content: MAX_SLOT_WARNING});
        } else if (timesMaps.length > 6) {
            timesMaps = timesMaps.slice(0, 6);
            messages.push({severity: "warning", content: MAX_SLOT_WARNING});
        }
        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};
        metadata["scheduleSlotsInfo"] = {...this.scheduleRef.current.getScheduleSlotsInfo()};


        let scheduleSlotsInfo = metadata["scheduleSlotsInfo"];
        let emptySchedules = [];
        for (let i = 0; i < timesMaps.length; i += 1) {
            if (Object.keys(timesMaps[i]).length === 0) emptySchedules.push(i);
        }
        if (emptySchedules.length > 0) {
            return [{messages: [{severity: "error", content: `The following schedule slots were not filled in: ${emptySchedules.map(index => scheduleSlotsInfo.nameArray[index]).join(",")}`}]}];
        }
        let slotsInfo = metadata["slotsInfo"];
        let {result, missing} = findSolution(timesMaps, intervalsMaps, slotsInfo["lengthArray"], metadata["span"] * metadata["scale"], metadata["wrap"]);
        let solutionDatas = [];
        if (Object.keys(result).length === 0) {
            return [{messages: [{severity: "error", content: "Could not find a scheduling for any slots"}]}];
        }
        for (let [index, selectedIndex] of Object.entries(result)) {
            let timesMap = timesMaps[index];
            let intervalsMap = intervalsMaps[selectedIndex];
            let solutionData = {};
            for (let rowIndex in intervalsMap) {
                let array = [];
                solutionData[rowIndex] = array;
                let timesSchedule = timesMap[rowIndex];
                let slotsSchedule = intervalsMap[rowIndex];
                let timesIndex = 0;
                let slotsIndex = 0;
                let curr = slotsSchedule[slotsIndex][0];
                while (timesSchedule !== undefined && timesIndex < timesSchedule.length && slotsIndex < slotsSchedule.length) {
                    if (timesSchedule[timesIndex][0] <= curr) {
                        array.push({interval: timesSchedule[timesIndex], borderColor: getColor(slotsInfo["colorArray"][selectedIndex], 800), color: getColor(slotsInfo["colorArray"][selectedIndex], 500), label: [scheduleSlotsInfo.nameArray[index]]})
                        curr = timesSchedule[timesIndex][1];
                        timesIndex += 1;
                    } else if (slotsSchedule[slotsIndex][1] > timesSchedule[timesIndex][0]) {
                        array.push({interval: [Math.max(curr, slotsSchedule[slotsIndex][0]), timesSchedule[timesIndex][0]], color: getColor(slotsInfo["colorArray"][selectedIndex], 300), label: [slotsInfo["nameArray"][selectedIndex]]})
                        curr = timesSchedule[timesIndex][0];
                    } else {
                        array.push({interval: slotsSchedule[slotsIndex], color: getColor(slotsInfo["colorArray"][selectedIndex], 300), label: [slotsInfo["nameArray"][selectedIndex]]})
                        curr = slotsSchedule[slotsIndex][1];
                        slotsIndex += 1;
                    }
                }
                while (timesSchedule !== undefined && timesIndex < timesSchedule.length) {
                    array.push({interval: timesSchedule[timesIndex], borderColor: getColor(slotsInfo["colorArray"][selectedIndex], 800), color: getColor(slotsInfo["colorArray"][selectedIndex], 500), label: [scheduleSlotsInfo.nameArray[index]]})
                    timesIndex += 1;
                }
                while (slotsIndex < slotsSchedule.length) {
                    array.push({interval: [Math.max(curr, slotsSchedule[slotsIndex][0]), slotsSchedule[slotsIndex][1]], color: getColor(slotsInfo["colorArray"][selectedIndex], 300), label: [slotsInfo["nameArray"][selectedIndex]]})
                    curr = slotsSchedule[slotsIndex][1];
                    slotsIndex += 1;
                }
            }
            solutionDatas.push({title: `${scheduleSlotsInfo.nameArray[index]} → ${slotsInfo.nameArray[selectedIndex]}`, solutionData: solutionData})
        }
        if (missing.length > 0) {
            messages.push({severity: "warning", content: "Could not schedule: " + missing.map((index) => scheduleSlotsInfo["nameArray"][index]).join(", ")});
        }
        if (timesMaps.length < intervalsMaps.length) {
            messages.push({severity: "info", content: MORE_SLOTS_UNASSIGNED});
        } else if (timesMaps.length > intervalsMaps.length) {
            messages.push({severity: "info", content: MORE_EVENTS_UNASSIGNED});
        }

        let tables = [];
        for (let i = 0; i < solutionDatas.length; i += 1) {
            tables.push({messages: messages, table: solutionDatas[i]});
        }
        return tables;
    }

    processScheduleCardData(scheduleData, scheduleStartDay, scheduleEndDay, scheduleSelectAll, metadata) {
        return process2dData(scheduleData, scheduleStartDay, scheduleEndDay, scheduleSelectAll, this.state.time, metadata, this.denormalizeData, scaleData(metadata["scale"]));
    }

    componentDidMount() {
        document.title = `${capitalize(DISTRIBUTION_SCHEDULER_NAME)} scheduler`;
    }
}

export default withErrorBoundary(DistributionSchedulerPage);