import React from "react";
import Stack from '@mui/material/Stack';
import ActionsCard from "../components/ActionsCard";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Paper from '@mui/material/Paper';
import ShareDialog from '../components/Dialogs/ShareDialog';
import getTableRowsAndCols, { normalizeDayData, normalizeTimeData, denormalizeDayData, denormalizeTimeData } from "../utils/TableUtils";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DownloadIcon from '@mui/icons-material/Download';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';
import { printSolution, process1dData, process2dData, scaleData } from '../utils/DataUtils';
import { ClickTable } from '../components/ClickTable';
import { TouchTable } from '../components/TouchTable';
import { CheckTable } from '../components/CheckTable';
import ShareIcon from '@mui/icons-material/Share';
import AddSlotDialog from "../components/Dialogs/AddSlotDialog";
import DeleteSlotDialog from "../components/Dialogs/DeleteSlotDialog";
import {FEATURE_SUBSCRIPTION_ALERT, SCHEDULER_RUNNING_ALERT} from "../utils/Constants";
import { log } from "../utils/AnalyticsUtils";
import CircularProgress from "@mui/material/CircularProgress";
import EmptyTable from "./EmptyTable";
import { defaultEndDate, defaultEndTime, defaultStartDate, defaultStartTime } from "../initial_data";

class SchedulerPage extends React.Component {
    constructor(props) {
        super(props);
        this.paperRef = React.createRef();
        this.tableRef = React.createRef();
        this.slotsRef = React.createRef();
        this.shareDialogRef = React.createRef();
        this.addDialogRef = React.createRef();
        this.deleteDialogRef = React.createRef();
        this.scheduleRef = React.createRef();
        this.dialogAction = this.dialogAction.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.state={scheduleActive: true, displayActive: false, complexTableType: true, tableColor:"grey", 
            time: true, startTime: defaultStartTime, endTime: defaultEndTime, startDate: defaultStartDate, endDate: defaultEndDate, 
            interval: 60, startDay: 0, endDay: 6, span: 1, solverRunning: false};
    }

    getRef() {
        if (this.state.scheduleActive) {
            return this.scheduleRef.current;
        } else {
            return this.slotsRef.current;
        }
    }

    openDialog (action, passthroughVars) {
        return () => {
            switch(action) {
                case "add": {
                    let [content, attributes] = this.slotsRef.current.getDefaultAttributesContent();
                    this.addDialogRef.current.handleClickOpen({content: content, defaultAttributes: attributes}, passthroughVars);
                    break;
                }
                case "delete":
                    this.deleteDialogRef.current.handleClickOpen();
                    break;
                default:
                    this.shareDialogRef.current.handleClickOpen(this.scheduleRef.current.getSharingDetails());
            }
        }
    }

    dialogAction (action) {
        switch(action) {
            case "add":
                return (properties) => {
                    this.slotsRef.current.addSlot(properties.attributes);
                    //this.displayMessage(`"${properties.attributes.name}" added.`)
                }
            case "delete":
                return () => {
                    let deletedSlotId = this.getRef().deleteActiveSlot();
                    if (deletedSlotId === undefined) {
                        this.scheduleRef.current.handleClick();
                    }
                    //this.displayMessage("Slot deleted.")
                }
            case "share":
                return (enabled) => {
                    this.scheduleRef.current.saveState("sharingEnabled", enabled);
                }
            default:
                return;
        }
    }

    getActions() {
        return [
            { icon: this.state.solverRunning ? <CircularProgress size="1.2rem"/> : <PlayArrowIcon />, name: 'Run scheduler',
                action: this.state.solverRunning ? () => this.setState({snackbarMessage: SCHEDULER_RUNNING_ALERT, snackbarOpen: true}) : this.runSolver },
            { icon: <SaveIcon color="disabled" />, name: 'Save', action: () => this.setState({snackbarMessage: FEATURE_SUBSCRIPTION_ALERT, snackbarOpen: true})},
            { icon: <DownloadIcon />, name: 'Download', action: this.onClickDownloadTxt},
            { icon: <ShareIcon color="disabled"/>, name: 'Share', action: () => this.setState({snackbarMessage: FEATURE_SUBSCRIPTION_ALERT, snackbarOpen: true})}
        ];
    }

    onClickDownloadTxt = () => {
        log("button", {name: "Download txt"})
        this.saveToActiveSlot();
        const scheduleString = this.scheduleRef.current.toString(this.state.startDate, this.state.time);
        const slotString = `Slot availability:\n\n${this.slotsRef.current.toString(this.state.startDate, this.state.time)}`;
        const solutionString = `Schedule:\n\n${printSolution(this.getDownloadSolution())}`
        let htmlString = `${scheduleString}\n\n\n${slotString}\n\n\n${solutionString}`;
        const element = document.createElement("a");
        const file = new Blob([htmlString], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        let title = this.scheduleRef.current.state.title;
        let filteredTitle = title.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s+/g, "_");
        let timestamp = new Date().getTime();
        element.download = `${filteredTitle}_${timestamp}.txt`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    getDownloadSolution = () => {
        return this.scheduleRef.current.getSolution()
    }

    loadActiveToTable = () => {
        this.loadToTable(this.getRef().getScheduleData());
    }

    loadToTable = (scheduleData) => {
        if (this.tableRef.current !== null) {
            let [data, scheduleStart, scheduleEnd, timeFormat, selectAll] = scheduleData;
            this.tableRef.current.loadData(this.denormalizeData(data, scheduleStart, scheduleEnd, timeFormat), selectAll, timeFormat === this.state.time, this.state.clipboard);
        }
    }

    normalizeData(returnData) {
        if (this.state.time) {
            return [normalizeTimeData(returnData, this.state.interval), this.state.startTime, this.state.endTime];
        } else {
            return [normalizeDayData(returnData, this.state.span, this.state.startDay, this.state.endDay), this.state.startDay, this.state.endDay];
        }
    }

    denormalizeData = (normedData, scheduleStart, scheduleEnd, timeFormat) => {
        if (normedData === undefined || timeFormat !== this.state.time) {
            return undefined;
        }

        if (this.state.time) {
            return denormalizeTimeData(normedData, this.state.interval, scheduleStart, this.state.startTime, this.state.endTime)
        } else {
            return denormalizeDayData(normedData, this.state.span, scheduleStart, scheduleEnd, this.state.startDay, this.state.endDay)
        }
    }

    saveToActiveSlot = () => {
        if (this.tableRef.current !== null) {
            let [data, selectAll, clipboard] = this.tableRef.current.returnData()
            this.setState({"clipboard": clipboard})
            let [normalizedReturnData, scheduleStart, scheduleEnd] = this.normalizeData(data);
            this.getRef().saveDataToActive(normalizedReturnData, this.state.time, scheduleStart, scheduleEnd, selectAll);
        }
    }

    switchActive = (scheduleActive) => {
        return (newProps) => {
            this.saveToActiveSlot();
            this.setState({scheduleActive: scheduleActive, displayActive: false, tableColor: newProps["color"]}, () => this.loadToTable(newProps["data"]));
        }
    }

    tableChangeAction = (tableChangeMap) => {
        this.saveToActiveSlot();
        this.setState(tableChangeMap, this.loadActiveToTable);
    }

    propChangeAction = (propChangeMap) => {
        this.setState(propChangeMap);
    }

    displayMessage = (message) => {
        this.setState({snackbarMessage: message, snackbarOpen: true});
    }

    updateData = (scheduleData) => {
        this.loadToTable(scheduleData);
    }

    toggleDisplayActive = () => {
        if (this.state.displayActive) {
            this.setState({ displayActive: false }, this.loadActiveToTable);
        } else {
            this.setDisplayActive();
        }
    }

    setDisplayActive = () => {
        this.saveToActiveSlot();
        this.setState({ displayActive: true });
    }

    getTableType() {
        if (window.navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/i)) {
            return this.state.complexTableType ? TouchTable : CheckTable;
        } else {
            return this.state.complexTableType ? ClickTable : CheckTable;
        }
    }

    processScheduleCardData(scheduleData, scheduleStartDay, scheduleEndDay, scheduleSelectAll, metadata) {
        return process1dData(scheduleData, scheduleStartDay, scheduleEndDay, scheduleSelectAll, this.state.time, metadata, this.denormalizeData, scaleData(metadata["scale"]));
    }

    processSlotListData(slotListDatas, slotListStartDays, slotListEndDays, slotListSelectAlls, metadata) {
        return process2dData(slotListDatas, slotListStartDays, slotListEndDays, slotListSelectAlls, this.state.time, metadata, this.denormalizeData, scaleData(metadata["scale"]));
    }

    runSolver = async () => {
        let [tableRows, tableCols, metadata] = getTableRowsAndCols(this.state.time, this.state.startTime, this.state.endTime, this.state.startDate, this.state.endDate, this.state.interval, this.state.startDay, this.state.endDay, this.state.span);
        this.setDisplayActive();
        let [times, scheduleStartDay, scheduleEndDay, scheduleSelectAll] = this.scheduleRef.current.exportData(this.state.time);
        times = this.processScheduleCardData(times, scheduleStartDay, scheduleEndDay, scheduleSelectAll, metadata);
        let [scheduleDatas, startDays, endDays, selectAlls] = this.slotsRef.current.exportAllData(this.state.time);
        let intervalsMaps = this.processSlotListData(scheduleDatas, startDays, endDays, selectAlls, metadata);
        try {
            const solution = await new Promise(resolve => {
                this.setState({ solverRunning: true });
                log("button", { name: "Run solver" });
                resolve(this.findSolution(times, intervalsMaps, metadata));
            });
            await this.scheduleRef.current.saveSolution({ times: times, intervalsMaps: intervalsMaps, data: solution, rows: tableRows, cols: tableCols, timeFormat: this.state.time, metadata: metadata });
            this.setDisplayActive();
            this.setState({ solverRunning: false, snackbarMessage: "Solver run completed.", snackbarOpen: true });
        } catch (error) {
            this.setState({ solverRunning: false, snackbarMessage: "Solver run failed.", snackbarOpen: true });
        }
    }

    render() {
        let [tableRows, tableCols, metadata, terminalCols] = getTableRowsAndCols(this.state.time, this.state.startTime, this.state.endTime, this.state.startDate, this.state.endDate, this.state.interval, this.state.startDay, this.state.endDay, this.state.span);
        let actions = this.getActions(tableRows, tableCols, metadata);

        let table;
        if (this.state.displayActive) {
            let solution = this.scheduleRef.current.getSolution();
            if (solution !== undefined) {
                table = React.cloneElement(this.componentGenerators.displayTable(), {solution: solution, displayMessage: this.displayMessage})
            } else {
                table = <EmptyTable/>
            }
        } else {
            let Table = this.getTableType();
            table = <Table ref={this.tableRef} tableRows={tableRows} tableCols={tableCols} terminalCols={terminalCols} height={50} parentRef={this.paperRef} color={this.state.tableColor} />
        }
        let scheduleCardHeight = 74;
        return <Stack direction={{xs:'column', sm:'row'}} spacing={1} sx={{height: "100%", padding: 1, paddingTop: 0, boxSizing: "border-box"}}>
            <Stack spacing={1} sx={{height: "100%", '@media (max-width:599px)': {height:"50%"}}} >
                <Paper sx={{'@media (max-width:599px)': {minHeight:`${scheduleCardHeight}px`, overflow: 'auto' }}}>
                    {React.cloneElement(this.componentGenerators.scheduleCard(),
                        {ref: this.scheduleRef, height: scheduleCardHeight, slotClickAction: this.switchActive(true), color: "grey", tableChangeAction: this.tableChangeAction,
                            propChangeAction: this.propChangeAction, dataChangeAction: this.updateData, displayMessage: this.displayMessage, startTime: this.state.startTime, endTime: this.state.endTime,
                            startDate: this.state.startDate, endDate: this.state.endDate, startDay: this.state.startDay, endDay: this.state.endDay, time: this.state.time,
                            interval: this.state.interval, span: this.state.span, complexTableType: this.state.complexTableType,
                            selected: this.state.scheduleActive && !this.state.displayActive})}
                </Paper>
                <ShareDialog ref={this.shareDialogRef} action={this.dialogAction("share")}/>
                <DeleteSlotDialog ref={this.deleteDialogRef} action={this.dialogAction("delete")}/>
                <AddSlotDialog ref={this.addDialogRef} action={this.dialogAction("add")}/>
                <Paper className="menuItem" variant="outlined" style={{height:"100%", overflow: 'auto', backgroundColor: 'transparent'}}>
                    {React.cloneElement(this.componentGenerators.slotList(),
                        {ref: this.slotsRef, slotClickAction: this.switchActive(false), 
                            propChangeAction: this.propChangeAction, dataChangeAction: this.updateData, displayMessage: this.displayMessage,
                            timeFormat: this.state.time, selected: !this.state.scheduleActive && !this.state.displayActive,
                            openDialog: this.openDialog, rotateColors: true})}
                </Paper>
                <ActionsCard primaryAction={this.openDialog("delete")} secondaryAction={this.toggleDisplayActive}
                    tertiaryAction={this.openDialog("add")} displayActive={this.state.displayActive}/>
                <Snackbar open={this.state.snackbarOpen} autoHideDuration={3000} onClose={() => this.setState({snackbarOpen: false})} 
                    message={this.state.snackbarMessage} />
            </Stack>
            <Paper ref={this.paperRef} variant="outlined" style={{overflow: 'auto'}} sx={{'@media (max-width:599px)': {height:"50%"}}}>
                {table}
            </Paper>
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'fixed', bottom: 25, right: 25 }}
                icon={this.state.solverRunning ? <CircularProgress style={{ color: 'white' }} size={25}/> : <SpeedDialIcon/> }
            >
                {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action["action"]}
                />
                ))}
            </SpeedDial>
        </Stack>
    }
}

export default SchedulerPage;