import React from 'react';
import DynamicScheduleCardList from '../../components/DynamicScheduleCardList';
import DynamicSlotList from '../../components/DynamicSlotList';
import DynamicSchedulerPage from './DynamicSchedulerPage';
import { generateNcSlotContent } from '../../utils/SlotContentFactory';
import {
    DISTRIBUTION_SCHEDULER_NAME,
    ERROR_SOLUTION_MESSAGE,
    INVALID_SLOT_DELETE_MESSAGE,
    MAX_SLOT_ADD_MESSAGE,
    MIN_SLOT_DELETE_MESSAGE,
    MORE_EVENTS_UNASSIGNED,
    MORE_SLOTS_UNASSIGNED,
    NO_SOLUTION_MESSAGE,
    PARTIAL_SOLUTION_MESSAGE,
    SLOTS_PER_PLAN
} from '../../utils/Constants';
import { capitalize } from '../../utils/StringUtils';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {getColor} from "../../utils/ColorMapper";
import {process2dData, scaleData} from "../../utils/DataUtils";
import {DynamicTabbedMultiDisplayTable} from "../../components/DynamicTabbedMultiDisplayTable";

class DynamicDistributionSchedulerPage extends DynamicSchedulerPage {
    static SOLVER_NAME = "distribution";
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <DynamicScheduleCardList key="scheduleCard" icon="/distribution_icon.png"  {...this.scheduleProps()}/>,
            slotList: () => <DynamicSlotList key="slotList" contentFactory={generateNcSlotContent(this.state.time)} {...this.slotProps()}/>,
            displayTable: () => <DynamicTabbedMultiDisplayTable key="displayTable" processRequest={this.processRequest}
                processResponse={this.processResponse} solverProps={{name: DynamicDistributionSchedulerPage.SOLVER_NAME, maxSolutions: 10}} {...this.displayProps()} />
        };
    }

    dialogAction (action) {
        switch(action) {
            case "add":
                return (properties) => {
                    if (properties.slotsAdd) {
                        this.slotsRef.current.addSlot(properties.attributes);
                    } else {
                        this.scheduleRef.current.addSlot(properties.attributes);
                    }
                }
            default:
                return super.dialogAction(action);
        }
    }

    openDialog (action, passthroughVars) {
        return () => {
            let limit = SLOTS_PER_PLAN[this.props.id][this.state.planName];
            switch(action) {
                case "add":
                    passthroughVars = passthroughVars || {};
                    if (passthroughVars["slotsAdd"] === undefined) {
                        passthroughVars["slotsAdd"] = !this.state.scheduleActive;
                    }

                    if (!passthroughVars["slotsAdd"] && this.scheduleRef.current.getSize() >= limit ||
                        passthroughVars["slotsAdd"] && this.slotsRef.current.getSize() >= limit) {
                        this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max ${limit})`});
                    } else {
                        let [content, attributes] = passthroughVars["slotsAdd"] ? this.slotsRef.current.getDefaultAttributesContent() : this.scheduleRef.current.getDefaultAttributesContent();
                        this.addDialogRef.current.handleClickOpen({content: content, defaultAttributes: attributes}, passthroughVars);
                    }
                    break;
                case "delete":
                    if ((this.state.scheduleActive && this.scheduleRef.current.getSize() <= 1)) {
                        this.setState({snackbarOpen: true, snackbarMessage: MIN_SLOT_DELETE_MESSAGE});
                    } else if (this.state.displayActive || (!this.state.scheduleActive && this.slotsRef.current.getSize() === 0)) {
                        this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
                    } else {
                        super.openDialog(action, {}, passthroughVars)();
                    }
                    break;
                default:
                    super.openDialog(action, {}, passthroughVars)();
            }
        }
    }


    async findSolution(timesMap, intervalsMaps, metadata) {
        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};
        metadata["scheduleSlotsInfo"] = {...this.scheduleRef.current.getScheduleSlotsInfo()};
        let input = this.processRequest(timesMap, intervalsMaps, metadata);
        //console.log(timesMap, intervalsMaps);
        let response = await this.callSolver(DynamicDistributionSchedulerPage.SOLVER_NAME, input);
        //console.log("response", response);
        return this.processResponse(response, metadata);
    }

    processRequest(timesMap, intervalsMaps, ) {
        let schedules = timesMap.map((time) => { return {"rows": time}; });
        let slots = intervalsMaps.map((intervalsMap) => { return {"rows": intervalsMap}});
        return {"schedules": schedules, "slots": slots};
    }

    processResponse(response, metadata) {
        if (response === undefined) {
            return [{messages: [{severity: "error", content: ERROR_SOLUTION_MESSAGE}]}];
        }
        let slotsInfo = metadata["slotsInfo"];
        let scheduleSlotsInfo = metadata["scheduleSlotsInfo"];
        let messages = [];
        if (response.status?.result === "FAILURE") {
            messages.push({severity: "error", content: NO_SOLUTION_MESSAGE});
        } else if (response.status?.result === "PARTIAL_SUCCESS") {
            messages.push({severity: "warning", content: `${PARTIAL_SOLUTION_MESSAGE} ${response.status.messageData?.failureIndices?.map((index) => scheduleSlotsInfo["nameArray"][index]).join(", ")}`});
        }
        if (slotsInfo["nameArray"].length < scheduleSlotsInfo["nameArray"].length) {
            messages.push({severity: "info", content: MORE_EVENTS_UNASSIGNED});
        } else if (slotsInfo["nameArray"].length > scheduleSlotsInfo["nameArray"].length) {
            messages.push({severity: "info", content: MORE_SLOTS_UNASSIGNED});
        }

        let tables = response.schedule.map((schedule, index) => {
            let solutionData = {};
            for (let rowIndex of Object.keys(schedule.table)) {
                solutionData[rowIndex] = schedule.table[rowIndex].map((entry) => {
                    let overlap = entry.data.overlap;
                    return {"interval": [entry.start, entry.end], "borderColor": getColor(slotsInfo["colorArray"][index], 800), "color": getColor(slotsInfo["colorArray"][index], overlap ? 500 : 200), "label": [overlap ? slotsInfo["nameArray"][schedule.data.assignmentIndex] : scheduleSlotsInfo["nameArray"][index]]};
                });
            }
            return {solutionData: solutionData, title: `${scheduleSlotsInfo.nameArray[index]} → ${slotsInfo.nameArray[schedule.data.assignmentIndex]}`};
        });
        return tables.map((table) => { return{messages: messages, table: table}});
    }

    processScheduleCardData(scheduleData, scheduleStartDay, scheduleEndDay, scheduleSelectAll, metadata) {
        return process2dData(scheduleData, scheduleStartDay, scheduleEndDay, scheduleSelectAll, this.state.time, metadata, this.denormalizeData, scaleData(metadata["scale"]));
    }

    componentDidMount() {
        document.title = `${capitalize(DISTRIBUTION_SCHEDULER_NAME)} scheduler | Snug Scheduler`;
    }
}

export default withErrorBoundary(DynamicDistributionSchedulerPage);