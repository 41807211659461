import React from "react";
import DynamicSlotList from "./DynamicSlotList";
import { generateNSlotContent } from "../utils/SlotContentFactory";
import ScheduleCardList from "./ScheduleCardList";
import {onSnapshot, doc, collection, query, orderBy, addDoc, updateDoc} from "firebase/firestore";
import { defaultValues } from "../utils/DataUtils"
import {findAppendedOrder} from "../utils/OrderEncoderUtils";
import {OPERATION_FAILED, WRITE_FAILED_DETAIL} from "../utils/Constants";
import { defaultEndDate, defaultEndTime, defaultStartDate, defaultStartTime } from "../initial_data";
import { initializeSolutionDocumentData } from "../utils/DocumentUtils";

export default class DynamicScheduleCardList extends ScheduleCardList {
	constructor(props) {
		super(props);
		this.slotListRef = React.createRef();
        this.state.selected = true;
		this.solution = [];
		onSnapshot(query(collection(this.props.rootPath, "solutions"), orderBy("order")), (snapshot) => {
			let solution = [];
			snapshot.forEach((doc) => {
				let data = doc.data();
				initializeSolutionDocumentData(data);
				solution.push(data);
				data.id = doc.id;
			});
			this.solution = solution;
		});
		onSnapshot(this.props.rootPath, (document) => {
            let data = document.data();
            //console.log(`${document.id} => ${JSON.stringify(data)}`);
			if (this.solution === undefined) {
				if (data.solution) { //on initial load only
					data.solution.metadata.start = new Date(data.solution.metadata.start.seconds * 1000)
					super.saveSolution(JSON.parse(data.solution.data), JSON.parse(data.solution.colors), JSON.parse(data.solution.names), data.solution.rows, data.solution.cols, data.solution.timeFormat, data.solution.metadata);
				} else {
					this.solution = false;
				}
			}
			let defaultData = {name: "Untitled schedule", solution: {colors: "[]", data: "[]", names: "[]", uid: 0}, sharing: {enabled: false, key: "0"}, complexTableType: true}
			defaultValues(data, defaultData);
            this.setState({title: data.name, sharing: data.sharing}, () => {
				if (this.props.selected) {
					this.props.tableChangeAction({"complexTableType": data.complexTableType})
				}
				onSnapshot(doc(this.props.rootPath, this.state.sharing.key, "DEFAULT"), (doc) => {
					let data = doc.data();
					let defaultData = {endDate: defaultEndDate, endDay: 6, endTime: defaultEndTime, interval: 30, span: 1, startDate: defaultStartDate, startDay: 0, startTime: defaultStartTime, timeFormat: true}
					defaultValues(data, defaultData);
					this.props.tableChangeAction({"time": data.timeFormat, "interval": data.interval, "span": data.span, "startTime": data.startTime,
						"endTime": data.endTime, "startDate": data.startDate, "endDate": data.endDate, "startDay": data.startDay, "endDay": data.endDay});
				}, () => {
					this.props.propChangeAction({"notFound": true});
				});
			});
        }, () => {
			this.props.propChangeAction({"notFound": true});
		});
	}

	saveState(field, value) {
		let publicAccessSet = new Set(["time", "interval", "span", "startTime", "endTime", "startDate", "endDate", "startDay", "endDay"]);
		let fieldMapping = {time: "timeFormat", interval: "interval", span: "span", startTime: "startTime",endTime: "endTime",
			startDate: "startDate", endDate: "endDate", startDay: "startDay", endDay: "endDay", title: "name",
			sharingEnabled: "sharing.enabled", complexTableType: "complexTableType"};
		if (publicAccessSet.has(field)) {
			updateDoc(doc(this.props.rootPath, this.state.sharing.key, "DEFAULT"), {
				[fieldMapping[field]]: value
			}).catch(() => {
				this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
			});
		} else {
			updateDoc(this.props.rootPath, {
				[fieldMapping[field]]: value
			}).catch(() => {
				this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
			});
		}
		super.saveState(field, value);
	}

	saveDataToActive(data, timeFormat, scheduleStart, scheduleEnd, selectAll) {
		updateDoc(this.props.rootPath, {
			"name": this.state.title,
		})
		super.saveDataToActive(data, timeFormat, scheduleStart, scheduleEnd, selectAll);
	}

	getAuxContentList() {
		return [<DynamicSlotList key="slotList" ref={this.slotListRef} data={this.props.data} slotClickAction={this.props.slotClickAction} 
			propChangeAction={this.props.propChangeAction} dataChangeAction={this.props.dataChangeAction} displayMessage={this.props.displayMessage}
			timeFormat={this.props.timeFormat} selected={this.props.selected}  contentFactory={generateNSlotContent(this.props.timeFormat)}
			color="grey" rootPath={collection(this.props.rootPath, "scheduleSlots")} onLoadFinish={this.props.onLoadFinish}/>];
	}

	getSharingDetails() {
		return this.state.sharing;
	}

	async saveSolution(solution) {
		if (solution) {
			solution.order = findAppendedOrder(this.solution.map((solution) => solution.order));
			solution.title = `solution_${Date.now()}`
			solution.times = JSON.stringify(solution.times);
			solution.intervalsMaps = JSON.stringify(solution.intervalsMaps);
			try {
				const docRef = await addDoc(collection(this.props.rootPath, "solutions"), solution);
				//this.solution.push(solution);
				return docRef.id;
			} catch (error) {
				this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
				return null;
			}
		}
	}

	render() {
		if (this.props.hide) {
			return this.getAuxContentList();
		} else {
			return super.render();
		}
	}

	componentDidUpdate() {
		if (!this.props.hide && this.slotListRef.current?.state.orderToSlotIndex.length === 0) {
			this.slotListRef.current.addSlot({name: this.state.title + " (Slot 1)"});
		}
	}
}